<template>
  <Card>
    <h4>Informações do Veículo</h4>
    <form @submit.prevent="saveVehicle()">
      <div class="row">
        <div class="col">
          <image-upload
            select-text="Adicionar imagem do veículo"
            change-text="Alterar"
            remove-text="remover"
            :src="false"
            :showDeleteOnSrc="false"
            @change="addImageChange"
          >
          </image-upload>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <image-upload
            v-for="image in images"
            :key="image.id"
            select-text="Imagem do veículo"
            change-text="Alterar"
            remove-text="remover"
            :src="image.image_url"
            :showDeleteOnSrc="true"
            previewStyle="height: 150px;"
            @change="file => onImageChange(file, image.id)"
          >
          </image-upload>
        </div>
      </div>

      <div class="form-row">
        <base-input
          v-model="form.year"
          type="text"
          class="col-md-3"
          placeholder="Ano"
          label="Ano"
        >
          <HasErrorForm :form="form" field_name="year" slot="helperText" />
        </base-input>

        <base-input
          v-model="form.color"
          type="text"
          class="col-md-3"
          placeholder="Cor"
          label="Cor"
        >
          <HasErrorForm :form="form" field_name="color" slot="helperText" />
        </base-input>

        <base-input
          v-model="form.license_plate"
          type="text"
          class="col-md-3"
          placeholder="Placa"
          label="Placa"
        >
          <HasErrorForm
            :form="form"
            field_name="license_plate"
            slot="helperText"
          />
        </base-input>

        <base-input
          v-model="form.brand"
          type="text"
          class="col-md-3"
          placeholder="Marca"
          label="Marca"
        >
          <HasErrorForm :form="form" field_name="brand" slot="helperText" />
        </base-input>
      </div>

      <div class="text-right">
        <base-button native-type="submit" type="warning">
          Salvar
        </base-button>
      </div>
    </form>
  </Card>
</template>

<script>
import Card from '@/components/Cards/Card'
import Form from 'vform'

import { BaseButton, ImageUpload } from '@/components'
import BaseInput from '@/components/Inputs/BaseInput'

import HasErrorForm from '@/components/HasErrorForm'
import axios from 'axios'

export default {
  name: 'NewEditVehicle',
  data: () => ({
    form: false,
    vehicle_id: false,
    image_preview: null,
    images: []
  }),
  async created () {
    if (!this.driver_id) return

    const {
      data: { vehicles }
    } = await this.$http.get(`/drivers/${this.driver_id}/vehicles`)

    if (vehicles[0] !== undefined) {
      const vehicle = vehicles[0]
      this.vehicle_id = vehicle.id

      this.form = new Form({
        year: vehicle.ano,
        color: vehicle.cor,
        license_plate: vehicle.placa,
        brand: vehicle.marca,
        image_url: []
      })

      this.images = vehicle.images
    } else {
      this.form = new Form({ image_url: [] })
    }
  },
  methods: {
    async addImageChange (file) {
      await this.onImageChange(file)
    },
    async onImageChange (file, image_id = null) {
      const imageIndex = this.images.findIndex(i => i.id === image_id)
      // let image = this.images[imageIndex]

      if (file === null && image_id) {
        await this.form.delete(
          `/drivers/${this.driver_id}/vehicles/image/${image_id}`
        )
        this.images.splice(imageIndex, 1)
        return
      }

      const form = new Form({ image: file })

      if (image_id) {
        form.id = image_id
      }

      // subtitui o plugin formatando manualmente o object-to-formdata
      const dataObj = new FormData()
      Object.keys(form).map(key => {
        // if (Array.isArray(form[key])) {
        //   form[key].forEach(element => {
        //     dataObj.append(`${key}[]`, element)
        //   })
        // } else {
        dataObj.append(key, form[key])
        // }
      })

      if (this.vehicle_id) {
        const {
          data: { data }
        } = await axios.post(`drivers/${this.driver_id}/vehicles/image`, dataObj)
        image_id = data.id
      }

      if (imageIndex < 0) {
        this.images.push({
          id: image_id || this.images.length + 1,
          file: file,
          image_url: URL.createObjectURL(file),
          is_preview: true
        })
      } else {
        // eslint-disable-next-line no-undef
        this.images[imageIndex].id = data.id
      }
    },
    async saveVehicle () {
      if (!this.driver_id) return

      if (this.vehicle_id) {
        this.form.id = this.vehicle_id
      }
      const formdata = new FormData()
      Object.keys(this.form).map(key => {
        formdata.append(key, this.form[key])
      })
      const {
        data: { vehicle }
      } = await this.form.post(`/drivers/${this.driver_id}/vehicles`, formdata)

      this.$notify({
        type: 'primary',
        message: this.vehicle_id ? 'Veículo atualizado!' : 'Veículo Criado!',
        icon: 'tim-icons icon-bell-55'
      })

      if (!this.vehicle_id) {
        this.vehicle_id = vehicle.id
        this.images.forEach(async image => {
          await this.onImageChange(image.file)
        })
      }
    }
  },
  props: ['driver_id'],
  components: { Card, BaseInput, BaseButton, ImageUpload, HasErrorForm }
}
</script>

<style scoped></style>
