<template>
  <div class="row">
    <div class="col-12">
      <h2 v-if="!this.$route.params.id">Cadastro de Motoristas</h2>
      <h2 v-if="this.$route.params.id">Atualização de Motorista</h2>

      <Card>
        <h4>Informações Pessoais</h4>
        <form @submit.prevent="saveDriver()" autocomplete="off">

          <div class="row">
            <div class="col">
              <image-upload type="avatar" select-text="Avatar do Motorista" change-text="Alterar" remove-text="remover"
                :src="image_preview" :showDeleteOnSrc="image_preview !== null" @change="onImageChange">
              </image-upload>
              <base-input v-model="form.name" type="text" placeholder="Nome" label="Nome">
                <HasErrorForm :form="form" field_name="name" slot="helperText" />
              </base-input>

              <div class="form-row">

                <base-input v-model="form.email" type="text" class="col-md-6" placeholder="Email" label="Email para login"
                  autocomplete="off">
                  <HasErrorForm :form="form" field_name="email" slot="helperText" />
                </base-input>

                <base-input label="Telefone para login" class="col-md-6">
                  <the-mask v-model="form.cell_phone" class="form-control" placeholder="Telefone"
                    :mask="['(##) ####-####', '(##) #####-####']" slot="default" />
                  <HasErrorForm :form="form" field_name="cell_phone" slot="helperText" />
                </base-input>

                <base-input v-model="form.password" type="text" class="col-md-6" placeholder="Senha para o Motorista"
                  label="Senha para o Motorista" autocomplete="off">
                  <HasErrorForm :form="form" field_name="password" slot="helperText" />
                </base-input>
              </div>

              <div class="form-row">
                <base-input type="text" class="col-md-3" label="CEP">
                  <the-mask v-model="form.cep" class="form-control" placeholder="CEP" :mask="['#####-###']"
                    slot="default" />
                  <HasErrorForm :form="form" field_name="cep" slot="helperText" />
                </base-input>

                <base-input v-model="form.address" type="text" class="col-md-3" placeholder="Endereço" label="Endereço">
                  <HasErrorForm :form="form" field_name="address" slot="helperText" />
                </base-input>

                <base-input v-model="form.address_number" type="number" class="col-md-3" placeholder="Número"
                  label="Número">
                  <HasErrorForm :form="form" field_name="address_number" slot="helperText" />
                </base-input>

                <base-input v-model="form.address_reference" type="text" class="col-md-3" placeholder="Referência"
                  label="Referência">
                  <HasErrorForm :form="form" field_name="address_reference" slot="helperText" />
                </base-input>
              </div>

              <div class="form-row">
                <base-input class="col-md-12" label="Descrição">
                  <textarea v-model="form.information" aria-describedby="addon-right addon-left" slot="default"
                    placeholder="Descrição" class="form-control textarea"></textarea>
                  <HasErrorForm :form="form" field_name="information" slot="helperText" />
                </base-input>
              </div>

              <label>Endereço <strong>DO</strong> Motorista: </label>
              <div class="form-row">
                <div class="col-md-4">
                  <base-dropdown title-classes="btn btn-warning btn-block" titleTag="div" :title="selectedProvince
                    ? `Estado: ${selectedProvince.name}`
                    : 'Estado'
                    ">
                    <a v-for="province in provinces" :key="province.id" class="dropdown-item" href="#"
                      @click="selectProvince(province)">{{ province.name }}</a>
                  </base-dropdown>
                </div>

                <div class="col-md-4">
                  <base-dropdown title-classes="btn btn-warning btn-block" titleTag="div" :title="selectedCity ? `Cidade: ${selectedCity.name}` : 'Cidade'
                    ">
                    <a v-if="!selectedProvince" class="dropdown-item" href="#">Selecione um Estado</a>
                    <a v-if="selectedProvince && cities.length <= 0" class="dropdown-item" href="#">Nenhuma cidade
                      encontrada</a>
                    <span v-if="selectedProvince && cities.length > 0">
                      <a v-for="city in cities" :key="city.id" class="dropdown-item" href="#" @click="selectCity(city)">{{
                        city.name }}</a>
                    </span>
                  </base-dropdown>

                  <HasErrorForm :form="form" field_name="city_id" slot="helperText" />
                </div>

                <div class="col-md-4">
                  <base-dropdown title-classes="btn btn-warning btn-block" titleTag="div" :title="selectedDistrict
                    ? `Bairro: ${selectedDistrict.name}`
                    : 'Bairro'
                    ">
                    <a v-if="!selectedCity" class="dropdown-item" href="#">Selecione uma Cidade</a>
                    <a v-if="selectedCity && districts.length <= 0" class="dropdown-item" href="#">Nenhum Bairro
                      encontrado</a>
                    <span v-if="selectedCity && districts.length > 0">
                      <a v-for="district in districts" :key="district.id" class="dropdown-item" href="#"
                        @click="selectDistrict(district)">{{ district.name }}</a>
                    </span>
                  </base-dropdown>
                </div>
              </div>

              <div class="form-row">
                <base-radio name="active" class="col-md-3" v-model="isActive">
                  Ativo
                </base-radio>

                <base-radio name="inactive" class="col-md-3" v-model="isActive">
                  Inativo
                </base-radio>
              </div>
            </div>

            <div class="col">
              <div class="form-row">
                <h5><strong>Bairros</strong> em que o Motorista atua:</h5>

                <div class="col-md-12">
                  <el-select multiple filterable v-model="form.work_districts_id" placeholder="Bairros"
                    @change="paintWhite">
                    <el-option v-for="district in districts" :value="district.id" :label="district.name"
                      :key="district.id"></el-option>
                  </el-select>
                </div>

                <h5 v-if="!!form.work_districts_id && !!form.work_districts_id.length">
                  <strong>Horários</strong> da bairros em que o Motorista atua:
                </h5>

                <div v-if="!!form.work_districts_id && !!form.work_districts_id.length" class="col-md-12">
                  <collapse>
                    <collapse-item v-for="(district_id, index) in form.work_districts_id"
                      :title="getDistrictName(district_id)" :name="getDistrictName(district_id)" :key="index">
                      <base-checkbox v-for="shift in shifts" :key="shift.id" class="mb-3"
                        :checked="districtShiftSelected(getDistrictById(district_id), getShiftById(shift.id))" inline
                        @input="selectDistrictShift(district_id, shift.id)">
                        {{ shift.shift_name }}
                      </base-checkbox>
                    </collapse-item>
                  </collapse>
                </div>
                <!-- fim de bairros -->

                <!-- inicio de escolas -->

                <h5 style="margin-top: 30px;"><strong>Escolas</strong> em que o Motorista atua:</h5>

                <div class="col-md-12">
                  <el-select multiple filterable v-model="form.work_schools_id" v-on:change="selectSchools"
                    placeholder="Escolas">
                    <el-option v-for="school in schools" :value="school.id" :label="school.name"
                      :key="school.id"></el-option>
                  </el-select>
                </div>

                <h5 v-if="schools_shift.length > 0">
                  <strong>Horários</strong> da escola em que o Motorista atua:
                </h5>

                <div v-if="schools_shift.length > 0" class="col-md-12">
                  <collapse>
                    <collapse-item v-for="school in schools_shift" :title="school.name" :name="school.name"
                      :key="school.id">
                      <base-checkbox v-for="shift in shifts" :key="shift.id" class="mb-3"
                        :checked="school.ids.includes(shift.id)" inline @input="selectSchoolShift(school, shift.id)">
                        {{ shift.shift_name }}
                      </base-checkbox>
                    </collapse-item>
                  </collapse>
                </div>

                <base-input v-model="form.imei" type="text" placeholder="Imei" label="Imei" class="col-md-12">
                  <HasErrorForm :form="form" field_name="imei" slot="helperText" />
                </base-input>

                <div class="col">
                  <base-input label="CPF">
                    <the-mask v-model="form.cpf" class="form-control" placeholder="CPF" :mask="['###.###.###-##']"
                      slot="default" />
                    <HasErrorForm :form="form" field_name="cpf" slot="helperText" />
                  </base-input>

                  <base-input label="CNH">
                    <the-mask v-model="form.cnh" class="form-control" placeholder="CNH" :mask="['###########']"
                      slot="default" />
                    <HasErrorForm :form="form" field_name="cnh" slot="helperText" />
                  </base-input>

                  <HasErrorForm :form="form" field_name="district_id" slot="helperText" />
                </div>

                <div class="col">
                  <base-input v-model="form.age" type="text" placeholder="Idade" label="Idade">
                    <HasErrorForm :form="form" field_name="age" slot="helperText" />
                  </base-input>
                </div>
              </div>
            </div>
          </div>
          <div class="text-right">
            <base-button native-type="submit" type="warning" :disabled="loading">
              Salvar
            </base-button>
          </div>
        </form>
      </Card>

      <NewEditVehicle v-if="driver_id" :driver_id="driver_id" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Form from 'vform'
import Card from '@/components/Cards/Card'
import { Select, Option } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import { TheMask } from 'vue-the-mask'

import {
  BaseDropdown,
  BaseCheckbox,
  Collapse,
  CollapseItem,
  ImageUpload
} from '@/components'
import BaseInput from '@/components/Inputs/BaseInput'
import BaseRadio from '@/components/Inputs/BaseRadio'
import NewEditVehicle from './NewEditVehicle'

import HasErrorForm from '@/components/HasErrorForm'

import axios from 'axios'

export default {
  name: 'NewEditDriver',
  data: () => ({
    form: false,
    isActive: 'active',
    provinces: [],
    cities: [],
    districts: [],
    district_shifts: [],
    district_shifts_ids: [],
    schools: [],
    shifts: [],
    schools_shift: [],
    schools_shifts_ids: [],
    selectedProvince: false,
    selectedCity: false,
    selectedDistrict: false,
    driver_id: false,
    image_preview: null,
    loading: false
  }),
  methods: {

    selectSchools() {
      this.loadSchoolShifts()
    },
    selectSchoolShift(school, shift_id) {
      const shiftIndex = this.schools_shifts_ids.findIndex(
        s => s.shift_id === shift_id && s.school_id === school.id
      )

      if (shiftIndex >= 0) {
        this.schools_shifts_ids.splice(shiftIndex, 1)
      } else {
        this.schools_shifts_ids.push({ shift_id, school_id: school.id })
      }
      this.paintWhite()
    },
    async onImageChange(file) {
      if (file === null && this.image_preview) {
        this.image_preview = null
        await this.form.delete(`/drivers/${this.driver_id}/image`)
      }

      this.form.avatar_url = file
    },
    resetLocation() {
      this.selectedCity = false
      this.selectedDistrict = false
      this.form.city_id = null
      this.form.district_id = null
    },
    async selectProvince(province) {
      this.selectedProvince = province
      const {
        data: { cities }
      } = await this.$http.get(`cities/${province.id}`)
      this.cities = cities
    },
    async selectCity(city) {
      this.selectedDistrict = false

      this.selectedCity = city
      const {
        data: { districts }
      } = await this.$http.get(`districts/${city.id}`)
      this.districts = districts

      const {
        data: { schools }
      } = await this.$http.get(`schools/${city.id}`)
      this.schools = schools
      this.loadSchoolShifts()
    },
    loadSchoolShifts() {
      this.schools_shift = this.schools
        .filter(f => this.form.work_schools_id.includes(f.id))
        .map(sh => {
          return {
            id: sh.id,
            name: sh.name,
            ids: this.schools_shifts_ids
              .filter(s => s.school_id === sh.id)
              .map(shift => shift.shift_id)
          }
        })

      this.paintWhite()
    },
    paintWhite() {
      setTimeout(() => {
        const elements = document.querySelectorAll('.el-select__tags-text')
        elements.forEach(element => {
          element.style.color = 'white'
        })
      }, 100)
    },
    // tudo relacionado a Bairros

    getDistrictById(id) {
      return this.districts.find(district => district.id === id)
    },

    getShiftById(id) {
      return this.shifts.find(shift => shift.id === id)
    },
    selectDistrict(district) {
      this.selectedDistrict = district
      this.form.city_id = this.selectedCity.id
      this.form.district_id = district.id
    },
    getDistrictName(district_id) {
      const district = this.districts.find(d => d.id === district_id)
      return district ? district.name : ''
    },
    districtShiftSelected(district, shift) {
      if (!district || !shift) {
        console.error('District or shift is undefined')
        return
      }

      return this.district_shifts_ids.find(
        ds => ds.district_id === district.id && ds.shift_id === shift.id
      )
    },

    selectDistrictShift(district_id, shift_id) {
      const shiftIndex = this.district_shifts_ids.findIndex(
        ds => ds.district_id === district_id && ds.shift_id === shift_id
      )

      if (shiftIndex >= 0) {
        this.district_shifts_ids.splice(shiftIndex, 1)
      } else {
        this.district_shifts_ids.push({ district_id, shift_id })
      }
    },

    async saveDriver() {
      this.loading = true
      try {
        if (this.driver_id) {
          this.form.id = this.driver_id
        }

        this.form.is_active = this.isActive === 'active' ? 1 : 0
        this.form.school_shifts = JSON.stringify(
          this.schools_shifts_ids.filter(sh =>
            this.form.work_schools_id.includes(sh.school_id)
          )
        )
        this.form.district_shifts = JSON.stringify(this.district_shifts_ids)
        console.log(this.form.district_shifts)

        // subtitui o plugin formatando manualmente o object-to-formdata
        const dataObj = new FormData()
        Object.keys(this.form).map(key => {
          dataObj.append(key, this.form[key])
        })

        const response = await axios.post('/drivers', dataObj)

        const data = response.data.data

        this.$notify({
          type: 'primary',
          message: this.driver_id
            ? 'Motorista atualizado!'
            : 'Motorista Criado!',
          icon: 'tim-icons icon-bell-55'
        })

        this.driver_id = data.id
        this.$router.push({ path: '/drivers' })
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    }
  },

  async created() {
    const {
      data: { provinces }
    } = await this.$http.get('provinces')
    this.provinces = provinces

    const { data } = await this.$http.get('shifts')
    this.shifts = data.data

    const districts = await this.$http.get('districts')
    this.districts = districts.data.data.data

    const schools = await this.$http.get('schools')
    this.schools = schools.data.data.data

    if (this.$route.params.id !== undefined) {
      this.driver_id = this.$route.params.id

      const {
        data: { data }
      } = await this.$http.get(`/driver/${this.$route.params.id}`)

      // map district_shifts to district_shifts_ids
      this.district_shifts_ids = data.district_shifts.map(ds => ({
        district_id: ds.district_shift_pivot.district_id,
        shift_id: ds.id
      }))

      // rest of the form data
      this.form = new Form({
        name: data.name,
        cnh: data.cnh,
        cpf: data.cpf,
        email: data.email,
        cep: data.zipcode,
        address_number: data.number,
        address: data.street,
        address_reference: data.reference,
        cell_phone: data.cell_phone,
        information: data.information,
        age: data.age,
        imei: data.imei,
        work_districts_id: data.work_districts_id,
        work_schools_id: data.work_schools_id
      })

      if (data.avatar) {
        this.image_preview = data.avatar
      }

      this.isActive = parseInt(data.is_active) ? 'active' : 'inactive'
      this.schools_shifts_ids = data.schools_shifts_ids

      await this.selectProvince(
        this.provinces.find(p => p.id === data.province_id)
      )
      await this.selectCity(this.cities.find(c => c.id === data.city_id))
      await this.selectDistrict(
        this.districts.find(c => c.id === data.district_id)
      )
    } else {
      this.form = new Form()

      await this.selectProvince(this.provinces.find(p => p.initials === 'PR')) // Paraná
      await this.selectCity(this.cities.find(c => c.ibge_code === '4108304')) // Foz do Iguaçu
    }
  },

  computed: {
    ...mapState('auth', ['user'])
  },
  components: {
    Card,
    BaseInput,
    BaseDropdown,
    BaseRadio,
    NewEditVehicle,
    ImageUpload,
    [Select.name]: Select,
    [Option.name]: Option,
    HasErrorForm,
    BaseCheckbox,
    TheMask,
    Collapse,
    CollapseItem
  }
}
</script>

<style lang="scss" scoped>
.textarea {
  .textarea {
    border-radius: 6px;
    border: 1px solid rgba(29, 37, 59, 0.5);
  }

  .textarea:focus {
    border-radius: 6px;
    border: 1px solid #1986aa;
  }
}
</style>
